<script>
import Navbar from "@/components/navbar";
import Service from "@/components/services";
import Features from "@/components/features";
import Pricing from "@/components/pricing";
import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import InfoTab from "../components/infotab.vue";
import StatisticCount from "../components/statisticCount.vue";
import CreatorsService from "../services/creators.sevice";
/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Service,
    Features,
    Pricing,
    Blog,
    Contact,
    Footer,
    InfoTab,
    StatisticCount,
  },
  data() {
    return {
      AwardList: [],
    };
  },
  created() {
    this.GetAwardsList();
  },
  methods: {
    GetAwardsList() {
      CreatorsService.GetAwardsList().then((res) => {
        this.AwardList = res.data.result;
        console.log(this.AwardList);
      });
    },
  },
};
</script>

<template>
  <div class="main-app">
    <Navbar />
    <div v-scroll-spy>
      <section
        class="hero-1-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/back-img4.jpg') + ')',
        }"
        id="home"
      >
        <div class="main-container">
            <div class="">
              <h4 class="creators-title">Davlat mukofotlari</h4>
            </div>
            <div class="content custom-scrollbar">
                <b-row class="">
                    <b-col
                    sm="12"
                    lg="6"
                    md="12"
                    v-for="(item, index) in AwardList"
                    :key="index"
                    >
                    <div class="cardLess mt-4">
                        <div class="rewardImg">
                            <img :src="'data:image/png;base64,' + item.photobase64" alt="" />
                        </div>
                        <div class="title-section">
                            <h1 class="rewardTitle">{{ item.awardtype }}</h1>
                            <h1 class="rewardTitle">{{ item.awardname }}</h1>
                            <div class="detail">
                                <h3>
                                Asos: 
                                <a :href="item.normativeurl" target="_blank">{{
                                    item.normativeurl
                                }}</a>
                                </h3>
                            </div>
                        </div>
                    </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <Footer />
      </section>
    </div>
  </div>
</template>
<style>
.title{
  width: 100%;
  text-align: center;
}
.title h1 {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #ecf0f1;
  font-size: 20px;
  text-transform: capitalize;
}
.cardLess {
  background-image: linear-gradient(
    to right top,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  width: 100%;
  min-height: 115px;
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: auto;
  padding: 10px 0;
}
.cardLess img {
  width: 40px;
  height: auto;
}
.rewardTitle {
  font-size: 19px;
  color: #fff;
  text-transform: uppercase;
}
.detail {
  align-items: center;
}
.detail h3 {
  font-size: 16px;
  color: #fff;
}

body {
  height: 100%;
  background-color: #ecf0f1;
  padding: 0rem;
  margin: 0;
}

/* .custom-scrollbar {
    height: 420px;
    overflow: scroll;
    overflow-x: hidden;
} */

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #5f6061;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5f6061;
}
</style>
